// Needs jquery request for mockajax
// Rest is jquery-free
jQuery(function($) {
  'use strict';
  var srcArr = [],
    lblArr = [],
    index,
    srcitem,
    firstResult,
    ctrl = document.getElementsByClassName('vt__input')[0],
    subButton = document.querySelector('.vt__submit');

  function addClass(elmnt, clss, base = document) {
    base.querySelectorAll(elmnt).forEach(function(elmnt){
      elmnt.classList.add(clss);
    });
  }
  
  function addResults(rArr, sq) {
    let results = document.querySelector('.vt__suggestions');
    rArr.forEach(function(item){
      let litem = document.createElement('li'),
          llink = document.createElement('a'),
          normalizedText = item.value.toLowerCase(),
          lid = normalizedText.replaceAll('-', '_'),
          current = window.location.href,
          resultText;
      llink.alt = item.value;
      llink.id = 'ipoclick-lnk-pa-zorgvergoedingen-' + lid;
      if (current.at(-1) === '/') {
        llink.href = window.location.href + normalizedText;
      } else {
        llink.href = window.location.href + '/' + normalizedText;
      }

      llink.href = llink.href?.toLowerCase().replace('niet-vergoed', 'niet-vergoed?zoekterm=' + item.label);

      llink.classList.add('vt__searchitem');

      resultText = `<strong>` + item.label + `</strong>`;
      resultText = resultText.replace(sq.toLowerCase(),`</strong>` + sq.toLowerCase() + `<strong>`);
      resultText = resultText + `<span class='vt__searchinfo'>; ` + item.desc + `</span>`;

      llink.innerHTML = resultText;

      litem.appendChild(llink);
      results.appendChild(litem);
    })
  }

  function clearResults() {
    let results = document.querySelector('.vt__suggestions');
    results.innerHTML = '';
  }

  function getPrioritizedResults(q) {
    let count = 5,
        countFinal = count,
        resArr=[],
        subArr = lblArr.filter(str => str.includes(q.toLowerCase()));
    
      subArr.sort(function (a, b) {
        return a.indexOf(q) - b.indexOf(q);
      });
      if (subArr.length < count) {
        count = subArr.length;
      }
      subArr = subArr.slice(0, count)
      subArr.forEach(function(lbl){
        let si = srcArr.filter(function(item) {
          return item.label.toLowerCase() === lbl;
        });
        /* maybe si has multiple items */
        si.forEach(function(sitem){
          if (!resArr.includes(sitem)) {
            resArr.push(sitem)
          }
        });
      })
      /* cap results again */
      if (resArr.length < countFinal) {
        countFinal = resArr.length;
      }
      resArr = resArr.slice(0, countFinal)
      return resArr;
  }
    
  // needs jquery request for mockajax
  function getSleutelwoorden(lbl, jaar, success) {
    $.support.cors = true;
    $.getJSON('/ApiProxy/Vergoedingentool/sleutelwoorden/' + lbl + '/' + jaar).done(success);
  }

  function hideAllProducts() {
    removeClass('.vt__product', 'is-selected');
    addClass('.vt__detail', 'display--hidden');
    setTabs('.vt__product a','-1');
    document.querySelectorAll('.vt__product').forEach(function(product) {
      product.querySelector('.vt__top').setAttribute('aria-expanded', 'false');
    })
  }

  function hideAllSubs() {
    removeClass('.vt__sub', 'is-selected');
    addClass('.vt__subtext + .vt__endpoints', 'visually-hidden');
    setTabs('.vt__endpoints a','-1');
    document.querySelectorAll('.vt__sub').forEach(function(sub) {
      sub.setAttribute('aria-expanded', 'false');
    })
  }

  function hideAllSubthemes() {
    hideAllSubs();
    removeClass('.vt__theme', 'is-selected');
    addClass('.vt__subtheme', 'visually-hidden');
    setTabs('.vt__sub','-1');
    setTabs('.vt__endpoints a','-1');
    document.querySelectorAll('.vt__theme').forEach(function(theme) {
      theme.setAttribute('aria-expanded', 'false');
    })
  }

  function goToFirstSuggest() {
    if(firstResult != undefined || firstResult != null) {
      window.location.href = firstResult.href;
    }
  }
          
  function hideAutoSuggest() {
    addClass('.vt__autosuggest', 'visually-hidden');
    ctrl.classList.remove('has-suggest');
  }

  function initOrderAttribute(items) {
    let counter = 1,
        rowCounter = 1;

    Array.prototype.forEach.call(items, function(item){
      if (item.classList.contains('vt__subtheme')) {
        item.style.setProperty('--attr-o', (rowCounter * 10) - 5);
        if (counter%2===1) {
          counter = 1+(rowCounter * 10);
          rowCounter = rowCounter+1;
        }
      } else {
        item.style.setProperty('--attr-o', counter);
        counter = counter + 1;
      }
    });
  }

  function mapData(data) {
    if (data && data.Model) {
      for (index = 0; index < data.Model.length; index++) {
        srcitem = {
          label: data.Model[index].Zoeksleutel,
          value: data.Model[index].RefTitel,
          desc: data.Model[index].Titel
        };
        srcArr.push(srcitem);
        if(!lblArr.includes(data.Model[index].Zoeksleutel.toLowerCase())) {
          lblArr.push(data.Model[index].Zoeksleutel.toLowerCase());
        }
      }
    }
  }

  function refillResults(sq) {
    if (sq.length>2) {
      document.querySelector('.vt__autosuggest').classList.remove('visually-hidden');
      ctrl.classList.add('has-suggest');
      let results = getPrioritizedResults(sq);
      clearResults();
      addResults(results, sq);
    } else {
      hideAutoSuggest()
    }
  }

  function removeClass(elmnt, clss, base = document) {
    base.querySelectorAll(elmnt).forEach(function(elmnt){
      elmnt.classList.remove(clss);
    });
  }
  
  function setTabs(elmnts, value, base = document) {
    base.querySelectorAll(elmnts).forEach(function(elmnt){
      elmnt.setAttribute('tabindex',value);
    })
  }

  function showThisProduct(product) {
    hideAllProducts();
    product.querySelector('.vt__detail').classList.remove('display--hidden');
    product.classList.add('is-selected');
    setTabs('a','0',product);
    product.querySelector('.vt__top').setAttribute('aria-expanded', 'true');

  }

  function showThisSub(sub) {
    hideAllSubs();
    removeClass('.vt__endpoints','visually-hidden',sub)
    setTabs('a','0', sub);
    sub.classList.add('is-selected');
    sub.setAttribute('aria-expanded', 'true');
  }

  function showThisSubtheme(item) {
    hideAllSubthemes();
    let subtheme = item.nextElementSibling;
    subtheme.classList.remove('visually-hidden');
    item.classList.add('is-selected');
    item.setAttribute('aria-expanded', 'true');
    setTabs('.vt__sub','0',subtheme);
    item.nextElementSibling.querySelectorAll('.vt__sub').forEach(function(sub) {
      if (sub.childElementCount === 1) {
        sub.removeAttribute('tabindex');
        sub.removeAttribute('aria-expanded');
        setTabs('.vt__endpoints a','0',sub)
      }
    })
  }

  let themes = document.querySelectorAll('.vt__themes > button, .vt__themes > div')
  initOrderAttribute(themes);

  // niet vergoed automatisch geopend
  document.querySelectorAll('.vt__product').forEach(function(product){
    if (product.getAttribute('product') == 'nietvergoed') {
      showThisProduct(product);
    }

    window.a11y.addAccesibleEventListeners(product, function(product) {
      if (product.classList.contains('is-selected')) {
        hideAllProducts();
      } else {
        showThisProduct(product);
      };
    });
  })

  // prevent double fireing of meetid
  document.querySelectorAll('.vt__detail a').forEach(function(detail){
    detail.addEventListener('click', function(e) {
      e.stopPropagation();
    });
  });

  document.querySelectorAll('.vt__theme').forEach(function(theme){
    window.a11y.addAccesibleEventListeners(theme, function(theme) {
      if (theme.classList.contains('is-selected')) {
        hideAllSubthemes();
      } else {
        showThisSubtheme(theme);
      };
    });
  });

  document.querySelectorAll('.vt__sub').forEach(function(sub){
    window.a11y.addAccesibleEventListeners(sub, function(sub){
      if (sub.classList.contains('is-selected')) {
          hideAllSubs();
      } else {
        showThisSub(sub);
      }
    });
  });

  if (document.getElementsByClassName('vt__input').length > 0) {
    let jaar = document.querySelector('.vt__period .pill.is-selected').textContent,
        label = document.querySelector('.vt__choice .pill.is-selected').textContent;
    if (label === 'ZorgActief') {
      label = 'Interpolis';
    }

    getSleutelwoorden(label, jaar, function(d) {
      mapData(d);

      ctrl.addEventListener('search', function() {
        hideAutoSuggest();
      });

      ctrl.addEventListener('keyup', function(e) {
        let sq = ctrl.value;
        if(e.key === 'Backspace' ||
            e.key === 'Delete') {
              refillResults(sq);
        }
        if(e.key === 'Enter') {
          goToFirstSuggest();
        }
      });

      ctrl.addEventListener('keydown', function(e) {
        let sq;
        firstResult = document.querySelector('.vt__searchitem');

        if (/[a-zA-Z0-9-_ ]/.test(e.key)) {
          sq = ctrl.value + String.fromCharCode(e.which);
        }
        refillResults(sq);
      });
    });
  }

  subButton.addEventListener('click', function(){
    firstResult = document.querySelector('.vt__searchitem');
    goToFirstSuggest();
  });
});
